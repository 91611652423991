<template>
  <div class="footer">
  </div>
</template>

<script>
  export default {
    components: {
    }
  };
</script>
