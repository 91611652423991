<template>
  <h1 class="header">
    JungHaus
  </h1>
</template>

<script>
  export default {
    components: {
    }
  };
</script>
