<template>
  <div>
    <Header></Header>
    <div class="body">
    <nuxt />
    </div>
    <Footer />
  </div>
</template>

<script>
  import Header from "~/layouts/Header";
  import Footer from "~/layouts/Footer";

  export default {
    components: {
      Header,
      Footer
    }
  };
</script>
